:root{
    --light-gray: #a8a8a8;
    --secondLight-gray: #CDD0D5;
    --med-gray: #808080;
    --white: #FFF;
    --black: #000;
    --bg-gray: #F3F4F5;
    --primary-gray: #434343;
    --apricot: #FCC9A8;
    --primary-oxford: #04132F;
    --light-pink:#EEEBF2;
    --light-blue: #E5F0F1;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@font-face {
    font-family: 'FreightBig';
    src: url('../assets/font/FreightBigProBold-Regular.woff2') format('woff2'),
         url('../assets/font/FreightBigProBold-Regular.woff') format('woff'),
         url('../assets/font/FreightBigProBold-Regular.ttf') format('truetype');
    font-display: swap;
}


@media (max-width: 640px) {
    ::-webkit-scrollbar {
        display: none;
    } 
}


.list::-webkit-scrollbar {
    width: 12px;
  }
  
.list::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track background */
    border-radius: 10px;
  }
  
.list::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 10px; /* Roundness of the scrollbar */
    border: 3px solid #f1f1f1; /* Space around the scrollbar */
  }
  
.list::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color when hovered */
  }


.select-block{
    bottom: 0;
    transform: translateY(100%);
    max-height: 600px;
    overflow: auto;
    z-index: 1022;
    min-width: 50px;
    border: 1px solid var(--light-gray);
    background-color: var(--white);
}

.select-block li a{
    display: block;
    font-size: 13px;
    padding: 7px 14px 7px 14px;
    white-space: nowrap;
    cursor: pointer;
}

.select-block li a:hover{
    background: var(--black);
    color: var(--white);
}


.search-box{
    border-radius: 30px;
    background-color: var(--bg-gray);
    height: 42px;
    border: 0;
    width: 100%;
    padding: 0px 10px 0px 35px;
}



.primary-gray{
    color: var(--primary-gray);
}


.search-box::placeholder{
    color: var(--primary-gray);
}

.category-list li a{
    border: 1px solid var(--secondLight-gray);
    border-radius: 30px;
    padding: 10px 20px;
}

.category-list li button{
    padding: 10px;
}


.category-list li button,
.category-list li a{
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: var(--med-gray);
}

.category-list li a:hover,
.category-list li a.active{
    color: var(--black);
    background-color: var(--apricot);
    border-color: transparent;
}

.notification-quantity{
    right: 10px;
    top: 0;
    transform: translateX(50%);
    width: 15px;
    height: 15px;
    padding: 0;
    text-align: center;
    align-self: center;
    border-radius: 50%;
    color: var(--white);
    background-color: #E95029;
    font-size: 11px;
}


.productCard {
    background-color: var(--light-pink);
    height: 240px;
    border-radius: 30px;
    overflow: hidden;
}

.productCard-image{
    width: 200px;
    height: 180px;
}

@media (max-width:640px) {
    .productCard-image{
        width: 100px;
        height: 100px;
    }
    .productCard{
        height: 140px;
        width: 140px;
        margin-bottom: 10px;
    }
}

@media (max-width:600px) {
    .productCard-image{
        width: 70px;
        height: 70px;
    }
    .productCard{
        height: 110px;
        width: 110px;
    }
}

@media (max-width:500px) {
    .productCard-image{
        width: 90px;
        height: 90px;
    }
    .productCard{
        height: 140px;
        width: 140px;
    }
}

@media (max-width:452px) {
    .productCard-image{
        width: 100px;
        height: 100px;
    }
    .productCard{
        height: auto;
        width: 47%;
        padding: 10px 15px;
    }
}
  
.productCard p{
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
    border-radius: 8px;
    color: var(--primary-oxford);
}

.main-heading{
    font-family: 'FreightBig';
    font-size: 38px;
    color: var(--primary-oxford);
    line-height: 35px;
}

@media (max-width:400px) {
    .main-heading{
        width: 85%;
    }
}

.sub-heading{
    font-family: 'FreightBig';
    font-size: 28px;
    color: var(--primary-oxford);
}

.multiProduct-Card{
    background-color: var(--light-pink);
    padding: 25px;
    border-radius: 30px;
}

@media (max-width:400px) {
    .multiProduct-Card{
        padding: 15px;
    }
}

.productTypes-details span{
    font-size: 16px;
    color: var(--primary-gray);
}

.singleproduct-card{
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    height: 200px;
    border-radius: 20px;
}


@media (max-width:400px) {
    .singleproduct-card{
        height: 150px;
    }
}

.DiscountProduct{
    padding: 10px 0px;
    height: 230px;
    border-radius: 20px;
}

.DiscountProduct .imageDiv{
    height: 70%;
}

.singleproduct-card span{
    font-size: 25px;
    color: #A9A9A9;
    font-weight: 600;
}

.singleproduct-card img,
.imageDiv img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.seeAll-btn{
    border: 1px solid var(--apricot);
    border-radius: 30px;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 500;
}

.seeAll-btn:hover{
    border-color: transparent;
    background-color: var(--apricot);
}

.productForDiv{
    background-color: var(--light-blue);
}

@media (max-width:368px) {
    .productForDiv{
        padding-bottom: 60px!important;
    }
}

.discount-strip{
    background-color: #FFEADB;
    color: #EB8948;
    padding: 5px 20px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
}

.favor-btn,
.left-favor-btn{
    width: 40px;
    height: 40px;
    background-color: var(--black);
    opacity: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: all 0.2s ease-in-out;
}

.favor-btn{
    top: 10px;
    right: 0;
    transform: translateX(-20%);
}

.left-favor-btn{
    bottom: 10px;
    right: 0;
    transform: translateX(-20%);
    opacity: 0.2;
}

.DiscountProduct:hover .favor-btn,
.categoryItem:hover .favor-btn{
    opacity: 0.2;
}

@media (max-width:640px) {
    .DiscountProduct .favor-btn,
    .categoryItem .favor-btn{
        opacity: 0.2;
    }   
}


.favor-btn i,
.left-favor-btn i{
    font-size: 20px;
    color: #fff;
}

.AdCard{
    border-radius: 30px;
    height: 250px;
    overflow: hidden;
}

.AdCard p{
    color: var(--primary-oxford);
    font-size: 30px;
    font-weight: 700;
    font-family: 'FreightBig';
    width: 50%;
}

.AdCard span{
    font-weight: 700;
    font-size: 35px;
    color: var(--primary-oxford);
}

@media (max-width:400px) {
    .AdCard{
        height: 180px;
    }
    .AdCard p{
        width: 75%;
    }
}

.bg-yellow{
    background-color: #FCE4A8;
}
.big-icon-image{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 10px;
    background-color: var(--white);
    bottom: -10%;
    right: -3%;
}
.small-icon-image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 10px;
    background-color: var(--white);
    top: 40%;
    right: 20%;
    transform: translate(-20%, -50%);
}

.small-icon-image img{
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.big-icon-image img{
    width: 60%;
    height: 60%;
    object-fit: contain;
}

@media (max-width:600px) {
    .AdCard p{
        font-size: 28px;
    }
    .small-icon-image{
        width: 80px;
        height: 80px;
        right: 16%;
    }
    .big-icon-image{
        width: 140px;
        height: 140px;
    }
    .AdCard span{
        font-size: 30px;
    }
}

@media (max-width:482px) {
    .AdCard{
        height: 180px;
    }
    .AdCard p{
        width: 70%;
        font-size: 18px;
    }
    .small-icon-image{
        width: 60px;
        height: 60px;
        right: 10%;
    }
    .big-icon-image{
        width: 100px;
        height: 100px;
    }
    .AdCard span{
        font-size: 18px;
    }
}


.category-text{
    font-weight: 700;
    font-family: 'FreightBig';
    font-size: 20px;
    color: var(--primary-oxford);
}

.slick-arrow{
    display: none!important;
}
  